import React from 'react'
import HeroSection2_1 from '../benefits/HeroSection2_1'
import HeroSection2_2 from '../benefits/HeroSection2_2'
import Image from '../../assests/images/2 1.png'
import DualMobileSection from '../benefits/DualMobileSection'
import BlueSection2 from '../benefits/BlueSection2'
import Image17 from '../../assests/images/image 17.png'
import Image173 from '../../assests/images/image 173.png'
import Image174 from '../../assests/images/image 174.png'
import Image175 from '../../assests/images/image 175.png'
import HeroSection from '../benefits/HeroSection'
import { Helmet } from 'react-helmet';
const Benefits_page2 = () => {
  return (
    <div className='mt-[50px]  '>

<Helmet>
        <title>Teens</title>
        <meta name="description" content="The Family Banking mobile app enables parents to guide their children in making trade-off decisions, managing money wisely, and understanding the importance of saving and investing—all within a single, seamless app experience." />
      </Helmet>
      <HeroSection2_2 spanTitle={"financial app for teens"} title={"The virtual account and"}/>
      <HeroSection2_1 ImageProp={Image} overlap={true} /> 



      <DualMobileSection/>  
      <section>
      <div className={` bg-[#E4F2FD] py-[50px]`}>
      <div className={`  max-w-[1245px] h-auto lg:h-[528px] mx-auto flex-col flex justify-center items-center lg:items-start lg:flex-row lg:justify-between lg:px-0 px-[50px]`}>
       <div className=' w-[300px] sm:w-[407px] '>
<img src={Image174} alt='loading' className='w-full h-full object-cover'/>
       </div>

       <div className=' w-full lg:w-[586px] h-auto mt-[50px] lg:h-[191px] '>
<h2 className='text-[#181818]  text-[28px] sm:text-[42px] AllTitle'><span className='text-[#1B7EF6] '> Set goals</span> for the future</h2>
<p className=' text-[16px] sm:text-[18px] text-[#565656]'>Saving for the next phone upgrade, college tuition, or a new car? Set aside earnings or save a portion of your or your teen's balance to achieve your future goals.</p>
       </div>
      </div>
    </div>
      </section>

      <BlueSection2
  direction="row-reverse" // or "row"
  title="Allowance. That easy way"
  desc="Say goodbye to the hassle of sending money to your kids through ATM or bank transfers and not knowing how it’s used. Our allowance feature simplifies the process for you. Set up recurring virtual allowances for your teens and kids, and receive notifications about important transactions, including when and how their virtual money is spent."
/>

 

      <section>
      <div 
      className={` justify-center items-center px-[16px]  py-10`} 
     
    >
      <div className="max-w-[1200px] mx-auto  flex-col lg:flex-row lg:h-[570px] flex justify-center  items-center lg:items-start  lg:justify-between px-[50px] lg:px-0 h-auto">
        
        <div className='w-[300px] sm:w-[430px] '>
          <img src={Image175} alt='loading image' className='w-full h-full object-cover' />
        </div>

        
        <div className=' w-full lg:w-[586px] mt-[50px]'>
          <h2 className=' text-[30px] sm:text-[42px]   AllTitle font-bold'>
          Send Virtual money instantly.
            <span className='text-[#1B7EF6]'> Anytime, anywhere,</span> 
         <span></span>   or  <span></span>
            <span className='text-[#1B7EF6]'> any virtual currency</span> 
          </h2>
          <p className=' text-[16px] sm:text-[18px] text-[#565656]'>While our app operates within a local ecosystem to teach kids about money, parents can still experience foreign currency exchange through our built-in feature*. Explore and manage virtual currency exchange as part of your financial journey.</p>
        </div>

        
      </div>
    </div>
      </section>  



      


      
    </div>
  )
}

export default Benefits_page2
