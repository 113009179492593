import React,{useState} from 'react'
import for1 from '../../assests/images/for1.png';
import for2 from '../../assests/images/for2.png';

import formobile from '../../assests/images/formobile.png';
import for4 from '../../assests/images/for4.png';
import formobile2 from '../../assests/images/formobile2.png';
import mdi_tag from '../../assests/images/mdi_tag.png';
import mdi1 from '../../assests/images/mdi1.png';
import mdi2 from '../../assests/images/mdi2.png';
import mdi3 from '../../assests/images/mdi3.png';
import blank from '../../assests/images/blank.png';
import { Helmet } from 'react-helmet';
const For_us = () => {

  // form realted data 
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    workEmail: '',
    companyName: '',
    partnership: '',
    additionalInfo: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the form (you can add more complex validation as needed)
    if (!formData.firstName || !formData.lastName || !formData.workEmail || !formData.companyName) {
      alert('Please fill in all required fields.');
      return;
    }

    // Print the form data to the console
    console.log(formData);
    alert('Form submitted!');

    // Reset form after submission
    setFormData({
      firstName: '',
      lastName: '',
      workEmail: '',
      companyName: '',
      partnership: '',
      additionalInfo: ''
    });
  };
  return (

    <div className="bg-white  flex flex-col lg:gap-y-[100px] py-10 mt-[100px] px-[16px]">
 <Helmet>
        <title>For Banks</title>
        <meta
          name="description"
          content="The Family Banking mobile app enables parents to guide their children in making trade-off decisions, managing money wisely, and understanding the importance of saving and investing—all within a single, seamless app experience."
        />
      </Helmet>
      {/* div start  */}
      <div className="container  mx-auto flex flex-col md:flex-row items-center rounded-xl px-4">

        <div className="md:w-1/2 w-full mb-6 md:mb-0 flex justify-center">
          <img
            src={for1}
            alt="Demo"
            className="rounded-xl w-full h-auto max-w-[534px] max-h-[399px]"
          />
        </div>


        <div className="md:w-1/2 w-full p-6 flex flex-col justify-center items-start">
        <h2 className="text-[48px] sm:text-[62px] AllTitle font-bold text-[#1B7EF6] mb-0">Family Wallet</h2>
        <h2 className="text-[28px] sm:text-[42px] AllTitle font-bold text-gray-700 mt-0">for Banks</h2>

          
          <p className="text-gray-700 mb-6">
          Engage the next generation of customers and expand your reach with kids and teens. Financial institutions can empower young people to build healthy financial futures, fostering financial literacy and responsibility.
          </p>
                    <button className="text-white px-6 py-3 rounded-lg" style={{ backgroundColor: '#1B7EF6' }}>
            Request demo
          </button>
        </div>
      </div>

      {/* div ended  */}

      {/* div start  */}
      <div className="container mx-auto   flex flex-col md:flex-row items-center rounded-xl px-4">
        {/* Text Section on the left */}
        <div className="md:w-1/2 w-full p-6 flex flex-col justify-center items-start md:mr-10">
          <h2 className="text-[28px] sm:text-[42px] AllTitle font-bold text-black mb-4 text-center">
          Support families in developing 
            <span className="text-4xl font-bold text-[#1B7EF6] mb-4 block">
            financially astute children
            </span>
          </h2>
          <p className="text-gray-700 mb-1">
          Provide your customers with the tools that have made our platform renowned.
          </p>
          <p className="text-gray-700 mb-6"> 
            Parents can automate allowances, manage chores, and send money instantly. Kids and teens learn to earn, save, and spend wisely, all while benefiting from flexible parental controls.
            </p>
        </div>

        {/* Image Section on the right */}
        <div className="md:w-[534px]    mb-6 md:mb-0 relative">
          <img
            src={for2}
            alt="Demo"
            className="rounded-xl w-full h-auto max-w-[534px] max-h-[399px]"
          />
          <div className='w-[130px] lightdiv h-[130px] bg-[#0073FF] absolute -bottom-[5%] -right-[4%] blur-2xl opacity-90 -z-1 rounded-full hidden lg:block'></div>

        </div>
      </div>

      {/* div ended  */}


      {/* div start  */}
      <div className="container mx-auto flex flex-col lg:flex-row items-center rounded-xl px-4">

        <div className="md:w-1/2 w-full mb-6 md:mb-0 relative flex  justify-center items-center">
          <img
            src={formobile}
            alt="mobile"
            className="relative z-10 left-[5%] w-[150px] sm:w-[200px] h-auto lg:w-[30%] lg:h-[50%]"


          />

          <div
            className="rounded-xl w-[300px] h-[200px] sm:h-[240px] sm:w-[500px] text-white  p-5 absolute" // Center text horizontally
            style={{
              backgroundColor: '#1B7EF6', // Set the background color
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',

              padding: '20px',
              fontSize: '2rem',
              zIndex: 5,
            }}
          >

          
            <h2 className="h-full flex justify-center text-[28px] sm:text-[42px] font-bold items-center w-[30%]">For Banks</h2> {/* Ensure text is centered */}
            
          </div>
        </div>

        {/* Text Section on the right */}
        <div className="md:w-1/2 w-full p-6 flex flex-col justify-center items-start md:ml-10">
          <h2 className="text-[28px] sm:text-[42px] AllTitle  font-bold text-black mb-4 text-center md:text-left">
          The fastest way to introduce
          <span className="text-3xl md:text-4xl font-bold mb-4 block"
            style={{ color: '#1B7EF6' }}>
            banking to kids and teens
          </span>

          </h2>
          <p className="text-gray-700 mb-4 text-center md:text-left">
          Our solution offers you the chance to provide a co-branded banking experience for your customers' kids, all without any technical work from your team.
          </p>
          <p className="text-gray-700 mb-6 text-center md:text-left">
          Parents can automate allowances, manage chores, and transfer money instantly. Kids and teens develop smart financial habits, learning to earn, save, and spend wisely, all while benefiting from customizable parental controls.

          </p>
          
        </div>
      </div>
      {/* div ended  */}


      {/* div start  */}

      <div className="container mx-auto flex flex-col md:flex-row items-center rounded-xl px-4"> {/* Added horizontal padding */}

        <div className="md:w-1/2 w-full p-6 flex flex-col justify-center items-center md:items-start md:mr-5"> {/* Center text on mobile */}
          <h2 className="text-[28px] sm:text-[42px] AllTitle  font-bold text-black mb-4 text-center md:text-left">
          Broaden your services and attain your objectives with
          <span className="text-3xl md:text-4xl font-bold mb-4 block" style={{ color: '#1B7EF6' }}>enhanced reach and effectiveness</span>
          </h2>
        </div>


        <div className="md:w-1/2 w-full mb-6 md:mb-0 flex justify-center">
          <img
            src={for4}
            alt="Demo"
            className="rounded-xl w-full h-auto max-w-[534px] max-h-[399px]"
          />
        </div>
      </div>

      {/* div ended  */}



      {/* div start  
      <div className="container mx-auto gap-y-[100px]  py-[50px] md:gap-y-[250px] mt-[100px] flex flex-col lg:flex-row items-center rounded-xl">



      <div className="w-[300px] h-[120px] sm:w-[500px] sm:h-[240px] relative rounded-3xl" style={{ backgroundColor: '#1B7EF6' }}>
          <div className='  sm:[622px] sm:h-[460px] absolute -top-[70px] sm:-top-[120px] '>
            <img src={formobile2} alt='loading...' className='w-full h-full object-cover' />
          </div>
        </div>
        <div className="md:w-1/2 w-full p-6 flex flex-col justify-center items-start md:ml-10">
          <h2 className="text-[28px] sm:text-[42px] AllTitle  font-bold text-black mb-4 text-center">Let us handle the hard work while <span></span>
            <span className="text-3xl md:text-4xl font-bold text-blue-600 mb-4 text-center">you focus on growing your business</span></h2>

        </div>
      </div>
      {/* div ended  */}

{/* div start  */}
<div className="container mx-auto py-6">
  {/* Header */}
  <div className="text-center mb-6">
    <h1 className="text-2xl md:text-4xl font-bold">
      Act <span className="text-blue-500">swiftly</span> and witness the{" "}
      <span className="text-blue-500">results</span>
    </h1>
  </div>

  {/* Card Section */}
  <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
    {/* Card 1 */}
    <div className="w-full md:w-1/4 p-2 flex flex-col items-center text-center">
      <div className="bg-blue-100 rounded-[10px] p-3 mb-3">
        <img src={mdi_tag} alt="Pricing Icon" className="w-10 h-10" />
      </div>
      <h2 className="text-lg font-semibold">Affordable flat-fee pricing</h2>
       <p className=' text-gray-400 w-[200px] md:w-[300px] text-center'>
       Our pricing model is designed to be transparent and budget-friendly, allowing you to access our services without the worry of hidden fees. With a straightforward flat-fee structure, you can easily plan your expenses and maximize your return on investment.
        </p>
    </div>

    {/* Card 2 */}
    <div className="w-full md:w-1/4 p-2 flex flex-col items-center text-center">
      <div className="bg-blue-100 rounded-[10px] p-3 mb-3">
        <img src={mdi1} alt="Customer Icon" className="w-10 h-10" />
      </div>
      <p className="font-medium">Partner-held customer Deposits</p>
      <p className=' text-gray-400 w-[200px] md:w-[300px] text-center'>
            Our partner-held customer deposits feature ensures that your clients' funds are securely managed by trusted partners. This arrangement not only enhances security and trust but also streamlines financial operations, allowing you to focus on delivering exceptional service. By leveraging our network of reliable partners, you can provide peace of mind to your customers while benefiting from seamless transaction management and reporting capabilities.
      </p>
    </div>

    {/* Card 3 */}
    <div className="w-full md:w-1/4 p-2 flex flex-col items-center text-center">
      <div className="bg-blue-100 rounded-[10px] p-3 mb-3">
        <img src={mdi2} alt="Enrollment Icon" className="w-10 h-10" />
      </div>
      <p className="font-medium">Seamless enrollment and turnkey reporting</p>
      <p className=' text-gray-400 w-[200px] md:w-[300px] text-center'>
      Experience a hassle-free enrollment process that gets you up and running quickly. Our turnkey reporting tools provide comprehensive insights into your performance, enabling you to make informed decisions without the complexity of traditional reporting systems.
      </p>
    </div>

    {/* Card 4 */}
    <div className="w-full md:w-1/4 p-2 flex flex-col items-center text-center">
      <div className="bg-blue-100 rounded-[10px] p-3 mb-3">
        <img src={mdi3} alt="Toolkit Icon" className="w-10 h-10" />
      </div>
      <p className="font-medium">Launch toolkit for easy activation and adoption</p>
      <p className=' text-gray-400 w-[200px] md:w-[300px] text-center'>
      Our launch toolkit equips you with everything you need to effectively activate and promote our services. From marketing materials to user guides, we ensure a smooth adoption process, empowering your team to utilize our offerings effortlessly and maximize their potential.
      </p>
    </div>
  </div>
</div>
{/* div ended  */}

{/* div start */}
<div className="container mx-auto py-6 px-4">
  <div className="flex flex-col md:flex-row items-center justify-between gap-y-6">

    {/* Left Section */}
    <div className="flex flex-col gap-y-4 items-center md:w-1/2 text-center md:text-left">

      <h1 className="text-3xl md:text-4xl font-bold mb-4 text-[#121212]">
        Obtain our <br />
        white-label app
      </h1>

      <div className="bg-gradient-to-r from-blue-400 to-blue-200 w-72 h-48 rounded-lg mx-auto md:mx-0 relative">
        <img src={blank} alt="" className="absolute -top-8 left-[10%] w-[180%] h-auto mx-auto pb-5" />
      </div>

      <p className="text-gray-600">
        Provide your contact details, and our team will get in touch with you.
      </p>
    </div>

    {/* Right Section (Form) */}
    <div className="md:w-[466px] bg-white shadow-lg rounded-lg p-6">
      <h2 className="text-[28px] sm:text-[32px] font-semibold text-center mb-4">
        Request a demo.
      </h2>

      <form className="space-y-4" onSubmit={handleSubmit}>
        <div>
          <label className="block text-gray-700">First Name*</label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="w-full border border-blue-600 rounded-lg px-4 py-2"
            placeholder="First Name"
            required
          />
        </div>

        <div>
          <label className="block text-gray-700">Last Name*</label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className="w-full border border-blue-600 rounded-lg px-4 py-2"
            placeholder="Last Name"
            required
          />
        </div>

        <div>
          <label className="block text-gray-700">Work Email*</label>
          <input
            type="email"
            name="workEmail"
            value={formData.workEmail}
            onChange={handleChange}
            className="w-full border border-blue-600 rounded-lg px-4 py-2"
            placeholder="Work Email"
            required
          />
        </div>

        <div>
          <label className="block text-gray-700">Company Name*</label>
          <input
            type="text"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            className="w-full border border-blue-600 rounded-lg px-4 py-2"
            placeholder="Company Name"
            required
          />
        </div>

        <div>
          <label className="block text-gray-700">How would you like to partner?*</label>
          <select
            name="partnership"
            value={formData.partnership}
            onChange={handleChange}
            className="w-full border border-blue-600 rounded-lg px-4 py-2"
            required
          >
            <option value="">Select an option</option>
            <option>White Label Opportunity</option>
            <option>SDK for our own app and customers</option>
            <option>Offer as a benefit to my employees</option>
            <option>Other</option>
          </select>
        </div>

        <div>
          <label className="block text-gray-700">Anything else you'd like to share with us?</label>
          <textarea
            name="additionalInfo"
            value={formData.additionalInfo}
            onChange={handleChange}
            className="w-full border border-blue-600 rounded-lg px-4 py-2"
            placeholder="Tell us about your business goals and how we can help."
            rows="3"
          ></textarea>
          <p className="text-gray-500 text-sm text-right">140 characters remaining</p>
        </div>

        <p className="text-xs text-gray-500 mt-4">
          * Required <br />
          By submitting this information, you consent to our{" "}
          <a href="#" className="text-blue-600 underline">
            Privacy Statement
          </a>{" "}
          and to receive emails about products and services.
        </p>

        <button
          type="submit"
          className="w-full bg-blue-600 text-white rounded-lg px-4 py-2 font-semibold mt-4"
        >
          Submit
        </button>
      </form>
    </div>

  </div>
</div>
{/* div ended  */}


     





      <div className='w-full'>


      </div>
    </div>
  )
}

export default For_us







