import React from 'react'
import Image2 from '../../assests/images/image 19.png'
const HeroSection3 = () => {
  return (
    
      <div className='px-[16px] py-0 sm:py-[50px]'>
  <div className='  pb-[50px] sm:pb-0 max-w-[1200px]  gap-[50px] lg:px-0 px-[50px] flex justify-center items-center lg:items-start lg:justify-between mx-auto flex-col lg:flex-row items-centermx-auto h-auto lg:h-[450px] '>
  
    <div className=' w-full h-auto lg:w-[550px] lg:h-[191px]'>
  <h2 className=' text-[28px] sm:text-[42px] AllTitle text-[#181818]'>Backers, Crowdfunding</h2>
  <p className=' text-[16px] sm:text-[18px] text-[#565656]'>You can share access to your pockets with backers, allowing them to support you directly by contributing funds to your account. This feature enables your loved ones to easily send virtual money to your pocket, providing you with the financial support you need while keeping everything seamlessly integrated within the app.</p>
    </div>

    <div className=' sm:w-[400px] md:w-[589px] md:h-[450px]'>
      <img src={Image2} alt='loading...' className='w-full h-full object-cover'/>
    </div>
  </div>

</div>
  )
}

export default HeroSection3
