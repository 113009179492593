import React from 'react'
import HeroSection2 from '../feature/HeroSection2'
import HeroSection3 from '../feature/HeroSection3'
import PigyBank from '../feature/PigyBank'
import LastSection from '../feature/LastSection'
import Image180 from '../../assests/images/image 180.png'
import HeroSection1_2 from '../benefits/HeroSection1_2'
import { Helmet } from 'react-helmet';
const FeatureSection = () => {
  return (<>
  <div >
  <Helmet>
        <title>Feature Section </title>
        <meta name="description" content="The Family Banking mobile app enables parents to guide their children in making trade-off decisions, managing money wisely, and understanding the importance of saving and investing—all within a single, seamless app experience." />
      </Helmet>
     <HeroSection1_2 spanTitle={"Endless Possibilities."} title={"One App,"}/>
<HeroSection2/>
<HeroSection3/>



<section>
      <div className={` bg-[#E4F2FD] pt-[50px]`}>
      <div className={`  max-w-[1245px] min-h-[575px]  mx-auto flex-col flex lg:flex-row justify-center items-center lg:items-start lg:justify-between lg:px-0 px-[50px]`}>
       <div className=' w-[300px] sm:w-[407px] '>
<img src={Image180} alt='loading' className='w-full h-full object-cover'/>
       </div>

       <div className=' w-full lg:w-[586px] h-auto mt-[50px] lg:h-[191px]'>
<h2 className=' text-[#1B7EF6]  text-[28px] sm:text-[42px] flex flex-row lg:flex-col AllTitle'><span className= 'text-[#181818]'> Financial literacy </span> gamification</h2>
<p className=' text-[16px] sm:text-[18px] text-[#565656]'>This feature offers a fun and engaging way for your kids to learn about financial concepts. It’s an effective method for developing essential financial skills while making the learning process enjoyable. Don’t miss out on this opportunity to combine education with fun!</p>
       </div>
      </div>
    </div>
      </section>
<PigyBank/>

<LastSection/>

  

 
  
</div>
 
  
  </>
  )
}

export default FeatureSection
