import React from 'react'

import Image4 from '../../assests/images/Group 43289.png'
const LastSection = () => {
  return (
  
      <div className='  py-[50px]' >
    <div className='max-w-[1200px]   items-center h-auto lg:h-[600px] px-[50px] lg:px-0  flex  lg:flex-row flex-col justify-between  mx-auto'>

       <div className='  flex justify-start w-[350px] sm:w-[400px] lg:w-[600px] lg:h-[540px]'>
        <img src={Image4} alt='loading...' className=' relative left-0 sm:-left-[50px] w-full h-full object-cover '/>
       </div>

       <div className='  w-full h-auto lg:w-[598px] lg:h-[250px]'>
<h2 className='text-[28px] sm:text-[42px] AllTitle text-[#181818]'>Free virtual currency experience or rewards</h2>
<p className='text-[16px] sm:text-[18px] text-[#565656]'>We're offering free virtual currency experiences and rewards to our users. Start using the app today and earn your rewards by following a few simple steps.</p>
       </div>


    </div>
  </div> 
   
  )
}

export default LastSection
