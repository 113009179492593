import { useState } from 'react';
import Image from '../../assests/images/image 18.png'
const AccordionItem = ({ title, content, isOpen, onClick }) => {
  return (
    <div>
    <button
      className="w-full text-left py-3 flex justify-between items-center"
      onClick={onClick}
    >
      <span className="AllTitle">{title}</span>
      <span className="text-blue-400 text-2xl">{isOpen ? 'x' : '+'}</span>
    </button>
  
    {/* Child div with smooth transition */}
    <div
      className={`text-[18px] border-l border-r text-[#565656] overflow-hidden transition-all duration-500 ease-in-out ${
        isOpen ? 'max-h-96 opacity-100 p-4' : 'max-h-0 opacity-0'
      }`}
      style={{ transition: 'max-height 1s ease, opacity 1s ease' }} // Ensure smooth transition
    >
      {content}
    </div>
  </div>
  
  );
};

export default function AccordionExample() {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="flex max-w-[1200px] mx-auto pt-[50px]  h-auto lg:h-[630px] flex-col lg:flex-row  gap-x-[100px] gap-4 p-4">
      {/* Left Section */}
      <div className=" w-full lg:w-[545px]">
        <h1 className=" text-[28px] sm:text-[42px] AllTitle ">
        Parents & kids <span className="text-[#1B7EF6]">love</span> Our mobile app!
        </h1>
        

        <div className="mt-4">
          {accordionData.map((item, index) => (
            <AccordionItem
              key={index}
              title={item.title}
              content={item.content}
              isOpen={openIndex === index}
              onClick={() => handleToggle(index)}
            />
          ))}
        </div>
      </div>

      {/* Right Section */}
      <div className=" w-[400px] h-[400px] sm:w-[500px] sm:h-[500px]">
        <img
          src={Image} 
          alt="Family Image"
          className="rounded-lg shadow-lg"
        />
      </div>
    </div>
  );
}

const accordionData = [
    {
      title: "One service, for the entire family!",
      content: "Not only does our app offer numerous benefits for you and your kids, but we also provide a companion app for grandparents, uncles, aunts, friends, each with its own special features!",
    },
  {
    title: "Virtual Pocket money for the kids",
    content: "Easily set up recurring automated allowance transfers for your kid.",
  },
  {
    title: "Sending virtual money, on demand",
    content: "Instantly send virtual money to your kids with the push of a few buttons, no more of that hassle of going to the ATM or the dozens of steps on your banking app.",
  },
  {
    title: "Virtual Money gifts",
    content: "Family and friends can get gift cards for your kid's card for their birthdays, celebrations or anything else.",
  },
  {
    title: "Charity, at your own pace",
    content: "We help introduce younger people to the idea of helping others and give them the satisfaction of doing so.",
  },
  {
    title: "Refer a friend, get rewarded",
    content: "Haven't we told you that parents love us? We are showing you our appreciation by offering you our referral and ambassador program.",
  },
];
