import React from 'react'
import HeroSection2 from '../benefits/HeroSection2'
import Image from '../../assests/images/Group 43248.png'
import FooterBanner from '../benefits/FooterBanner'
import Growsection2 from '../benefits/Growsection2'

import { Helmet } from 'react-helmet';
const Benefits_page3 = () => {
  return (
    <div className='mt-[100px]'>
      <Helmet>
        <title>Family & Friends</title>
        <meta name="description" content="The Family Banking mobile app enables parents to guide their children in making trade-off decisions, managing money wisely, and understanding the importance of saving and investing—all within a single, seamless app experience." />
      </Helmet>

      <section>
 <div className="w-full mt-[150px]  md:mb-[100px]  flex  px-[16px]  sm:justify-center sm:items-center">
          <div className=" w-[1174px] mx-auto pt-[20px] px-[20px] lg:px-0 md:pb-[20px] bg-[#FFFFFF] rounded-none sm:rounded-[50px] shadow-[8px_8px_20px_#d4e7ff] ">
            <div className=" max-w-[852px] h-[198px] mx-auto">
              <h2 className="text-center AllTitle  text-[#000000]] text-[28px]  md:text-[48px] w-full">
              Grandparents, <span className="text-[#1B7EF6]">family & friends</span>
              </h2>
              <p className=" text-[14px] sm:text-[18px] text-[#565656] text-center">
              Our app is designed for everyone—including your extended family and friends. It also simplifies life for families and friends living abroad. Connect with each other and enjoy valuable lessons in financial well-being for you and your kids.
              </p>
            </div>
          </div>
        </div>
      </section>

      <HeroSection2 overlap={false} ImageProp={Image} />
      <Growsection2 spanTitle={"Multi-layout"} title={" app thatgrows with your kids"} />
      <FooterBanner />
    </div>
  )
}

export default Benefits_page3
