import React from 'react'
import Image from '../../assests/images/image 30.png'
const DualMobileSection = () => {
  return (
    <div className=' pt-0 pb-[50px] lg:pt-[50px] px-[16px] '>
    <div className='max-w-[1200px]  gap-y-[100px] h-auto  lg:h-[480px] lg:justify-around px-[100px] md:px-0 items-center justify-center  mx-auto  flex-col flex lg:flex-row'>

{/* Image and content section */}
<div className='w-full h-auto flex flex-col md:flex-row items-center justify-between'>
  {/* Content */}
  <div className='w-full md:w-[620px] h-auto'>
    <h2 className='AllTitle text-[28px] md:text-[42px]'>
      Nurture a <span className='text-[#1B7EF6]'>healthy attitude</span> towards money
    </h2>
    <p className='text-[14px] sm:text-[18px] text-[#565656]'>
      Teens can earn rewards by completing tasks you set up, receiving weekly or one-time payments through the app. They can also set savings goals, and manage their budget—all within the app. We help you foster good spending habits for your teens!
    </p>
  </div>

  {/* Image section */}
  <div className='w-full h-full md:w-[498px] flex justify-center items-center relative'>
    <div className='w-full sm:w-[400px] md:w-[450px] h-auto'>
      <img src={Image} alt="loading image" className='w-full h-auto object-cover' />
    </div>
  </div>
</div>


</div>

    </div>
  )
}

export default DualMobileSection
