import React from 'react'
import HeroSection from '../benefits/HeroSection'
import HeroSection2 from '../benefits/HeroSection2'
import HeroSection3 from '../benefits/HeroSection3'
import GrowSection from '../benefits/GrowSection'
import BlueSection from '../benefits/BlueSection'
import DualSection from '../benefits/DualSection'
import DualSection2 from '../benefits/DualSection2'
import DualSection3 from '../benefits/DualSection3'
import Acc from '../benefits/AccordionItem'
import Image from '../../assests/images/shutterstock_1570550179 2.png'
import { Helmet } from 'react-helmet';
const Benefits_page1 = () => {
  return (
    <div className='mt-[50px] '>
      {/* Helmet for SEO */}
      <Helmet>
        <title>Parents & Kids</title>
        <meta name="description" content="The Family Banking mobile app enables parents to guide their children in making trade-off decisions, managing money wisely, and understanding the importance of saving and investing—all within a single, seamless app experience." />
      </Helmet>
      <HeroSection spanTitle={"smart habits "} title={"Financial education starts with"}/>
      <HeroSection2 ImageProp={Image} overlap={true}/>   
      <HeroSection3/>  
      <GrowSection/>  
      <BlueSection title={"Savings, it's their future"} desc={"Teach your kids the value of saving by setting aside their earnings for specific goals. You can create individual savings plans with targets for your kids—and even for yourself! Whether you're saving for your children's future college tuition or any other goal, our app makes it easy to plan and achieve your financial aspirations."}/> 

      <section>
      <DualSection2 
  title={" with the push of a button"} 
  spanTitle={"Chores,"} 
  desc={"Chores are a fantastic way to teach kids about responsibility, and our app makes it even easier to turn this into a rewarding experience. You can customize the chores, whether they're recurring or one-time tasks, and set the rewards to match your family’s needs. Think of it as a way to build not just financial skills but also a strong sense of responsibility and achievement. Just like planning for future goals, like saving for college, every small step and chore can contribute to bigger aspirations and teach valuable life lessons."} 
  bg={'#FFFFFF'} 
  direction={'flex-row'} 
/>

<DualSection3 
  title={"of your teens' locations while they explore"} 
  spanTitle={"Keep track"} 
  desc={"Receive real-time updates when their trips start and end, and get notified of any significant changes in their whereabouts. Dive into detailed location history and celebrate their journey with personalized updates and progress reports."} 
  bg={'#E4F2FD'} 
  direction={'flex-row-reverse'} 
/>
      </section> 

      <Acc/>  
     
    </div>
  )
}

export default Benefits_page1
