import React from 'react';
import { data } from '../../assests/data';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay'; // Autoplay CSS is optional, but good to have
import { Pagination, Autoplay } from 'swiper/modules'; // Import the Autoplay module

const ReactSlider = () => {
  return (
    <div className='w-full bg-black h-auto'>
      <div className='w-11/12 flex lg:flex-row flex-col h-auto p-15 sm:p-10 items-center justify-between mx-auto'>
        {/* Area div */}
        <div>
          <div className="bg-black text-white p-10">
            <h1 className="text-2xl md:text-5xl font-bold mb-4">
            Our features. Take a look at what's new, right now!
            </h1>
            <p className="text-sm md:text-xl">
            The app offers flexible features for the entire family. It’s not only beneficial for parents but also for kids, teens, friends, and other family members. With financial tools at your fingertips, managing money has never been easier!
            </p>
          </div>
        </div>

        {/* Swiper */}
        <div className='w-full  md:w-[600px] mx-auto max-h-[450px]'>
          <Swiper
            slidesPerView={1.5} // Show 1 full slide and half of the next one
            spaceBetween={5} // No space between slides to avoid gaps
            centeredSlides={false} // Avoid centering the slides
            pagination={{
              clickable: true,
            }}
            autoplay={{ // Use 'autoplay' with lowercase 'a'
              delay: 3000, // Delay between slides in milliseconds (3 seconds)
              disableOnInteraction: false, // Keeps autoplay running after user interaction
            }}
            loop={true} // Enable infinite loop
            modules={[Pagination, Autoplay]}
            breakpoints={{
              300: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 1.3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 1.3,
                spaceBetween: 30,
              },
            }} // Include the Autoplay module
            className="mySwiper "
          >
            {
              data.map((item) => {
                return (
                  <SwiperSlide key={item.id}>
                    <div className='w-full   mb-10 min-h-[400px] bg-black p-5 rounded-lg flex flex-col '>
                      {/* Image Section */}
                      <div className='w-full h-full sm:w-[400px] sm:h-[400px] rounded-[35px] relative  '>
                        <img src={item.image} alt={item.title} className='object-cover w-full h-full transition-transform duration-300 hover:scale-105 rounded-[35px]' />
                        

                      {/* Text Section */}
                      <div className='absolute w-full h-full top-0 left-0 p-[20px] flex flex-col items-center  mx-auto'>
                      <h2 className='font-bold text-xl sm:text-[28px] AllTitle lg:leading-[48px] text-white mt-4'>{item.title}</h2>
                      <p className='text-white text-[16px] sm:text-[18px] mt-2'>{item.description}</p> </div> </div>
                    </div>


                  </SwiperSlide>
                );
              })
            }
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default ReactSlider;
