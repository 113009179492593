import featureSectionImage1 from './images/featureImage1.png'
import featureSectionImage2 from './images/instant-transfer 1.png'
import featureSectionImage3 from './images/spending-history 1.png'
import featureSectionImage4 from './images/mobile-wallet 1.png'
import featureSectionImage5 from './images/flexible-controls 1.png'
import blog4 from './images/blog4.png'
import blog5 from './images/blog5.png'
import blog6 from './images/blog6.png'
import blog7 from './images/blog7.png'
import slider1 from './images/features-1.png'
import slider2 from './images/features-2.png'
import slider3 from './images/features-3.png'
import slider4 from './images/features-4.png'
import slider5 from './images/features-5.png'

export const Reviewdata=[
    
    {
    id:1,
    fname:"Esther Howard",
    desc: "I didn’t realize how helpful this app could be for me. It has been incredibly useful in monitoring my kids' spending."
},
    {
    id:2,
    fname:"Kimberley",
    desc: "I'll use this app whenever I want to assign chores to my kids. Such an easy way to send allowance and rewards. Love it so much!"
    
},
    {
    id:3,
    fname:"Daniel",
    desc: "I just wanted to let you know that your team has done a great job building this app. It's incredible how easy it is to operate the app and manage my money."
    
},

{
    id: 4,
    fname: "Amelia Jenkins",
    desc: "This app has changed the way we teach financial responsibility to our kids. They now understand the importance of saving and spending wisely."
},
{
    id: 5,
    fname: "Marcus Lee",
    desc: "I love the instant updates I get on my kids' spending. It gives me peace of mind knowing exactly how they're using their money."
},
{
    id: 6,
    fname: "Sophia Turner",
    desc: "The app makes managing chores and allowances so simple. My kids are more motivated to help around the house, and they love seeing their progress."
    
},

{
    id: 7,
    fname: "Oliver Smith",
    desc: "I love how the app encourages my kids to save for their goals. It’s fun for them, and it teaches valuable life skills at the same time."
  },
  {
    id: 8,
    fname: "Ava Martinez",
    desc: "This app has been a game-changer for our family. It's now so easy to set tasks, track savings, and even donate to charity with my kids."
  },
  {
    id: 9,
    fname: "Mason Lee",
    desc: "The best thing about this app is the transparency. I can always see where my kids are spending their money, and it's helped them make smarter decisions."
  }
  

]
export const data = [
    {
      id: 1,
      title: "Home finance app for everyone",
      description: "The mobile app always prioritizes convenience, personalization, and security for its users.",
       //{/*image: "https://cdn.pixabay.com/photo/2017/03/30/04/14/house-2187170_1280.jpg",*/}
        image:slider1
    },
    {
      id: 2,
      title: "Financial education through games",
      description: "Learning the game! Children learn about finance in a fun way through games on apps.",
      //image: "https://cdn.pixabay.com/photo/2021/03/16/12/03/woman-6099577_640.jpg",
        image:slider2
    },
    {
      id: 3,
      title: "Save first - Buy later",
      description: "Make it a habit to save up front to buy the things you need!",
      //image: "https://cdn.pixabay.com/photo/2021/07/08/06/38/fantasy-6396010_640.jpg",
        image:slider3
    },
    {
      id: 4,
      title: "Support family members",
      description: "Share goals with supporters for financial help!",
      //image: "https://cdn.pixabay.com/photo/2022/10/06/19/57/hd-wallpapers-7503531_640.jpg",
        image:slider4
    },
    {
      id: 5,
      title: "Experience and get rewarded with free virtual currency!",
      description: "Don't miss the chance to experience this virtual currency for the whole family!",
      //image: "https://cdn.pixabay.com/photo/2020/10/29/19/57/forest-5697136_640.jpg",
        image:slider5
    },
    // Add more objects for each slide...
  ];


export const FeaturesData=[
    
    {
    id:1,
    imageurl:featureSectionImage1,
    fname:"Notifications",
    desc:"Real time notifications, Simply tap on the notifications and be informed with your recent activities.  "
},
    {
    id:2,
    imageurl:featureSectionImage2,
    fname:"Instant Transfer",
    desc:"Move virtual money instantly between family members and friends! "
},
    {
    id:3,
    imageurl:featureSectionImage3,
    fname:"Spending History",
    desc:"Review your kids' spending history as well as your own with our banking partner. "
},
    {
    id:4,
    imageurl:featureSectionImage4,
    fname:"Mobile Wallet",
    desc:"Money doesn't grow on trees. With digital wallet service, you can have all your money in one place. "
},
    {
    id:5,
    imageurl:featureSectionImage5,
    fname:"Flexible Controls",
    desc:"Kids earn virtual money while you will have flexible access through secure monitoring and parental controls."
},

]

export const BlogData = [
  { id:1,
    date: "10 June 2023",
    Title: "News 1",
    Description:"Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Dynasty?",
    Button1:"Leadership",
    Button2:"Management",
    image:blog4

  },
  {   id:2,
      date: "10 June 2023",
      Title: "News 2",
      Description:"Mental models are simple expressions of complex processes or relationships.",
      Button1:"Leadership",
      Button2:"Management",
      Button3:"Frameworks",
      image:blog5
  },
  {
      id:3,
      date: "10 June 2023",
      Title: "News 3",
      Description:"Introduction to Wireframing and its Principles. Learn from the best in the industry.",
      Button1:"Design",
      Button2:"Research",
      image:blog6
  },
  {
      id:4,
      date: "10 June 2023",
      Title: "News 4",
      Description:"Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Dynasty?",
      Button1:"Leadership",
      Button2:"Management",
      image:blog4
  },
  {
      id:5,
      date: "10 June 2023",
      Title: "News 5",
      Description:"Mental models are simple expressions of complex processes or relationships.",
      Button1:"Product",
      Button2:"Research",
      Button3:"Frameworks",
      image:blog5
  },
  {
      id:6,
      date: "10 June 2023",
      Title: "News 6",
      Description:"Introduction to Wireframing and its Principles. Learn from the best in the industry.",
      Button1:"Design",
      Button2:"Research",
      image:blog6
  },

  { id:7,
      date: "10 June 2023",
      Title: "News 7",
      Description:"Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Dynasty?",
      Button1:"Leadership",
      Button2:"Management",
      image:blog5

    },
    {   id:8,
        date: "10 June 2023",
        Title: "News 8",
        Description:"Mental models are simple expressions of complex processes or relationships.",
        Button1:"Leadership",
        Button2:"Management",
        Button3:"Frameworks",
        image:blog6

    },
    {
        id:9,
        date: "10 June 2023",
        Title: "News 9",
        Description:"Introduction to Wireframing and its Principles. Learn from the best in the industry.",
        Button1:"Design",
        Button2:"Research",
        image:blog4

    },
    {
        id:10,
        date: "10 June 2023",
        Title: "News 10",
        Description:"Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Dynasty?",
        Button1:"Leadership",
        Button2:"Management",
        image:blog5
    },
    {
        id:11,
        date: "10 June 2023",
        Title: "News 11",
        Description:"Mental models are simple expressions of complex processes or relationships.",
        Button1:"Product",
        Button2:"Research",
        Button3:"Frameworks",
        image:blog6

    },
    {
        id:12,
        date: "10 June 2023",
        Title: "News 12",
        Description:"Introduction to Wireframing and its Principles. Learn from the best in the industry.",
        Button1:"Design",
        Button2:"Research",
        image:blog4
    },


    { id:13,
      date: "10 June 2023",
      Title: "News 13",
      Description:"Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Dynasty?",
      Button1:"Leadership",
      Button2:"Management",
      image:blog5
  
    },
    {   id:14,
        date: "10 June 2023",
        Title: "News 14",
        Description:"Mental models are simple expressions of complex processes or relationships.",
        Button1:"Leadership",
        Button2:"Management",
        Button3:"Frameworks",
        image:blog6
  
    },
    {
        id:15,
        date: "10 June 2023",
        Title: "News 15",
        Description:"Introduction to Wireframing and its Principles. Learn from the best in the industry.",
        Button1:"Design",
        Button2:"Research",
        image:blog4
  
    },
    {
        id:16,
        date: "10 June 2023",
        Title: "News 16",
        Description:"Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Dynasty?",
        Button1:"Leadership",
        Button2:"Management",
        image:blog4
    },
    {
        id:17,
        date: "10 June 2023",
        Title: "News 17",
        Description:"Mental models are simple expressions of complex processes or relationships.",
        Button1:"Product",
        Button2:"Research",
        Button3:"Frameworks",
        image:blog5
  
    },
    {
        id:18,
        date: "10 June 2023",
        Title: "News 18",
        Description:"Introduction to Wireframing and its Principles. Learn from the best in the industry.",
        Button1:"Design",
        Button2:"Research",
        image:blog4
    },
  
    { id:19,
        date: "10 June 2023",
        Title: "News 19",
        Description:"Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Dynasty?",
        Button1:"Leadership",
        Button2:"Management",
        image:blog5
  
      },
      {   id:20,
          date: "10 June 2023",
          Title: "News 20",
          Description:"Mental models are simple expressions of complex processes or relationships.",
          Button1:"Leadership",
          Button2:"Management",
          Button3:"Frameworks",
          image:blog4
  
      },
      {
          id:21,
          date: "10 June 2023",
          Title: "News 21",
          Description:"Introduction to Wireframing and its Principles. Learn from the best in the industry.",
          Button1:"Design",
          Button2:"Research",
          image:blog6
  
      },
      {
          id:22,
          date: "10 June 2023",
          Title: "News 22",
          Description:"Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Dynasty?",
          Button1:"Leadership",
          Button2:"Management",
          image:blog5
      },
      {
          id:23,
          date: "10 June 2023",
          Title: "News 23",
          Description:"Mental models are simple expressions of complex processes or relationships.",
          Button1:"Product",
          Button2:"Research",
          Button3:"Frameworks",
          image:blog5
  
      },
      {
          id:24,
          date: "10 June 2023",
          Title: "News 24",
          Description:"Introduction to Wireframing and its Principles. Learn from the best in the industry.",
          Button1:"Design",
          Button2:"Research",
          image:blog6
      },
      
    
];
