import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Navbar from './components/common/Navbar';
import Footer from './components/common/Footer';
import HomePage from './components/navbar/HomePage';
import FeatureSection from './components/navbar/FeatureSection';
import Benefits_page1 from './components/navbar/Benefits_page1';
import Benefits_page2 from './components/navbar/Benefits_page2';
import Benefits_page3 from './components/navbar/Benefits_page3';
import For_us from './components/navbar/For_us';
import Blog from './components/navbar/Blog';
import { Route, Routes } from 'react-router-dom';

const App = () => {
  const location = useLocation();
  const canonicalUrl = `https://www.littlewallet.co${location.pathname}`;

  return (
    <div className='overflow-x-hidden h-auto'>
      <Helmet>
        <title>Smart Money Habits for Families | Little Wallet</title>
        <meta name="description" content="Smart money habits and financial well-being for the entire family." />
        <meta property="og:title" content="Smart Money Habits for Families | Little Wallet" />
        <meta property="og:description" content="Teach kids and teens money management with gamification and family-friendly financial tools." />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>

      <Navbar />

      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/parents-kids' element={<Benefits_page1 />} />
        <Route path='/teens' element={<Benefits_page2 />} />
        <Route path='/family-friends' element={<Benefits_page3 />} />
        <Route path='/features' element={<FeatureSection />} />
        <Route path='/banks' element={<For_us />} />
        <Route path='/blogs' element={<Blog />} />
      </Routes>

      <Footer />
    </div>
  );
};

export default App;
