import React from 'react';
import HeroSectionImage from '../../assests/images/HeroSection.png'
const HeroSection = () => {
  return (
    <div className='w-full sm:mt-[100px] mb-[50px] flex justify-center items-center'>
    <div className={`container mt-[50px] gap-y-[50px] mb-[30px] flex lg:flex-row flex-col justify-between items-center mx-auto px-[20px] sm:px-0  w-[1200px]  `}>
      <div className='flex flex-col justify-center  w-full lg:w-[590px]'>
        <div className='flex flex-col gap-y-[32px]  '>
          <div className='flex flex-col'>
        <h2 className=' w-full lg:w-[530px] text-[28px] sm:text-[42px] AllTitle lg:text-[40px] text-[#181818] '>
        Smart Money habits and Financial wellbeing for the  <span className='text-[#1B7EF6]'>entire family</span> 
        </h2>
        <p className='  text-[14px] sm:text-[18px]  text-[#565656]'>
        A money management app designed to teach teens and kids the value of money, how to learn, earn, budget and save in a fun way with a touch of gamification! The sooner children learn about money, the more independent and responsible they will be in adulthood.
        </p>
        </div>
        <p className=' ext-[12px] text-[14px] text-[#1B7EF6]'>
        *We are a financial technology company, not a bank. Banking services provided by legitimate financial institution, regulated by the Monetary Authority.
        </p>
        </div>
      </div>

      <div className=' w-[100%] sm:w-[425px] sm:h-[413px] '>
        <img src={HeroSectionImage} className=' w-full h-full object-cover' alt='loading image'  />
      </div>
    </div>

   
    </div>
  );
};

export default HeroSection;
