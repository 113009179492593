import React from 'react'
import Image from '../../assests/images/Frame 427323698.png'
const HeroSection3 = () => {
  return (
    <div className='   w-full lg:mb-[30px] py-[30px]'>
      <div className=' max-w-[1200px]  h-auto md:h-[450px] px-3 lg:px-0 items-center  mx-auto flex md:flex-row flex-col gap-[50px] justify-between'>

{/* image section  */}
<div className='w-full h-auto md:w-[589px] md:h-[450px]'>
    <img src={Image} alt="loading image" className='w-full h-full object-cover'/>
</div>
{/* content  */}
<div className='w-full h-auto md:w-[550px] md:h-[191px]'>
<h2 className='AllTitle text-[28px] sm:text-[42px]'>It's all about <span className='text-[#1B7EF6]'>you and
your kids</span> </h2>
<p className='text-[18px] text-[#565656]'>We create apps just for you and your kids, with a focus on safety, security and convenience, along with age-appropriate features.</p>
</div>
      </div>
    </div>
  )
}

export default HeroSection3
