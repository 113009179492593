import React from 'react'
import Image from '../../assests/images/Group 43306.png'
const GrowSection = () => {
  return (
    <div className='w-full h-full bg-blue-300 py-[50px]'>
      <div className='max-w-[1200px] h-auto lg:h-[450px] px-[50px] lg:px-0 mx-auto flex lg:flex-row flex-col items-center gap-[40px] justify-between'>
       {/* for content  */}
       <div className='  w-full lg:w-[550px] '>
<h2 className='AllTitle text-[28px] sm:text-[42px]'><span className='text-[#1B7EF6] AllTitle'>Multi-layout</span> app that
grows with your kids</h2>
<p className='text-[#565656] text-[18px]'>We create apps just for you and your kids, with a focus on safety, security and convenience, along with age-appropriate features.</p>
       </div>

       {/* for image  */}
       <div className=' w-[300px] h-[280px] sm:w-[478px] sm:h-[438px]'>
<img src={Image} alt='loading image' className='w-full h-full'/>
       </div>
      </div>
    </div>
  )
}

export default GrowSection
