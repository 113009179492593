import React from 'react';
import { CgFacebook } from "react-icons/cg";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import Logo from '../../assests/images/Logo_white.png'
import { Link } from 'react-router-dom';



const Footer = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className='w-full px-[16px] text-white py-6  bg-[#1B7EF6] flex flex-col items-center '>
      <div className='flex container mx-auto flex-col py-6 gap-y-4 min-h-[100%]  lg:flex-row justify-between w-11/12'>
        
        {/* first div */}
        <div className="w-full lg:w-1/4 mb-6 sm:mb-0 items-center lg:items-start flex flex-col">
          <div className=" p-3 w-[70px] h-[70px] rounded-md mb-4">
            <img src={Logo} alt='loading..' className='w-full h-full object-cover'/>
          </div>
          <p>Smart money habits and financial well-being for the entire family.</p>
        </div>

        {/* second div */}
        <div className='flex w-full flex-wrap justify-center text-center sm:flex-nowrap'>
          <div className="w-full sm:w-1/3 mb-6 sm:mb-0">
            <h4 className="font-bold mb-4 cursor-pointer ">Benefits</h4>
             {/*<p className="mb-2 cursor-pointer "onClick><Link to='/Page1'>Parents & Kids</Link></p>*/}
          
            <p className="mb-2 cursor-pointer">
        <Link to='/parents-kids' onClick={scrollToTop}>Parents & Kids</Link>
      </p>
          

            <p className="mb-2 cursor-pointer ">
            <Link to='/teens' onClick={scrollToTop}>Teens</Link></p>
            <p className="mb-2 cursor-pointer ">
            <Link to='/family-friends' onClick={scrollToTop}>Family & Friends</Link></p>
          </div>

          <div className="w-full sm:w-1/3 mb-6 sm:mb-0">
            <h4 className="font-bold mb-4 cursor-pointer ">
            <Link to='/features' onClick={scrollToTop}>Features</Link></h4>
            {/*<p className="mb-2 cursor-pointer ">Blogs</p>*/}
          </div>

          <div className="w-full sm:w-1/3">
            <h4 className="font-bold mb-4 cursor-pointer ">
            <Link to='/banks' onClick={scrollToTop}>For Banks</Link></h4>
            {/*<p className="mb-2 cursor-pointer ">For Banks</p>
            
            <p className="mb-2 cursor-pointer underline">Lorem & Ipsum</p>
            <p className="mb-2 cursor-pointer underline">Lorem & Ipsum</p>*/}
          </div>
        </div>

        {/* third div */}
        <div>
          <div className='flex flex-col items-center'>
            <h4 className="font-bold mb-4 cursor-pointer">Follow us</h4>
            <div className="flex space-x-4">
              <a className="fab fa-facebook text-xl" href='#'><CgFacebook /></a>
              <a className="fab fa-youtube text-xl" href='https://www.youtube.com/channel/UCMDdpIOp1xiAs7I9EWk3fpw'><FaYoutube /></a>
              <a className="fab fa-instagram text-xl" href='https://www.instagram.com/littlewalletapp/'><FaInstagram /></a>
              <a className="fab fa-twitter text-xl" href='https://twitter.com/littlewalletco?s=21&t=1Y_opHYdIxobDJlyrd7MJg'><FaTwitter /></a>
              <a className="fab fa-linkedin text-xl" href='https://www.linkedin.com/company/little-wallet/?viewAsMember=true'><FaLinkedinIn /></a>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom section */}
      <div className="mt-8 flex flex-col justify-center items-center text-sm text-center sm:text-left font-bold">
           <p>All rights reserved. We are a financial technology company, not a bank.</p>
        {/* <p className="mt-4 sm:mt-0" href='https://littlewallet-web-landing.fly.dev/en/term-of-service'>Terms of Service | Privacy Policy</p>

        <div class="text-whitebase leading-snug text-center font-bold">
          <a class="hover:underline" href="/en/term-of-service">Terms of Service | </a>
          <a class="hover:underline" href="/en/privacy-policy">Privacy Policy </a>

          <a class="hover:underline inline" href="/en/term-of-service">Terms of Service | </a>
          <a class="hover:underline inline" href="/en/privacy-policy">Privacy Policy</a>*/}

          <div class="text-center">
  <a class="hover:underline inline-block" href="/en/term-of-service">Terms of Service</a>
  <span> | </span>
  <a class="hover:underline inline-block" href="/en/privacy-policy">Privacy Policy</a>
</div>

       
      </div>
    </div>
  );
};

export default Footer;


