import React from "react";
import BlueSectionImage from '../../assests/images/BlueSectionImage.png';

const BlueSection = () => {
  return (
    <div className="w-full px-[16px] bg-blue-500 mb-[50px] h-auto flex items-end">
      <div className="container pt-[40px] overflow-hidden mx-auto w-11/12 flex flex-col justify-between md:flex-row gap-y-[50px] items-start">
        <div className="text-white md:w-[50%] flex flex-col justify-center">
          <h2 className="text-left text-[35px] lg:text-[52px] AllTitle">
            Saving Pocket
          </h2>
          <h2 className="text-left text-[25px] lg:text-[42px] AllTitle">
            Let's unleash the power of saving, one goal at a time!
          </h2>
          <p className="text-left text-[14px] text-gray-100 md:text-[18px]">
            Saving Pocket, the ultimate tool for you and your kids to achieve financial goals with ease. Whether it’s for a special purchase, a vacation, or teaching financial responsibility, Saving Pocket offers personalized savings plans tailored to your needs. With clear milestones and progress tracking, it helps build healthy saving habits for both parents and children. Let’s unleash the power of saving, one goal at a time!
          </p>
        </div>
        <div className="w-[60%] h-[70%] md:w-[30%] md:h-[50%] lg:w-[419px] lg:h-[577px] flex justify-center items-end">
          {/* Image Section */}
          <img src={BlueSectionImage} className="w-full h-full object-cover" alt="Saving Pocket"/>
        </div>
      </div>
    </div>
  );
};

export default BlueSection;




