import React from 'react'
import HeroSection from '../homepage/HeroSection'
import GoalSection from '../homepage/GoalSection'
import Dual from '../homepage/Dual'
import BlueSection from '../homepage/BlueSection'
import ReactSlider from '../homepage/ReactSlider'
import Features from '../homepage/Features'
import RounderImage from '../homepage/RounderImage'
import Reviews from '../homepage/Reviews'
import ImageSection from '../homepage/ImageSection'
import MobileMoneyImage from '../../assests/images/MobileMoneyImage.png'
import ShoppiongImage from '../../assests/images/ShoppingImage.png'
import { Helmet } from 'react-helmet';
const HomePage = () => {
  return (
  
      <div className='mt-[50px]'>

<Helmet>
        <title>Home Page </title>
        <meta
          name="description"
          content="The Family Banking mobile app enables parents to guide their children in making trade-off decisions, managing money wisely, and understanding the importance of saving and investing—all within a single, seamless app experience."
        />
      </Helmet> 
      <HeroSection/>        
<GoalSection/>  

      <BlueSection/> 
       <section>

       <div className="flex flex-col gap-10">
  <Dual 
    title="Chores and tasks with rewards" 
    width={498} 
    height={605} 
    desc="Set tasks or chores for your kids monthly, weekly, or even daily! Teach your kids by rewarding for their efforts. Assign tasks and pay your kids when it's done. They'll learn fast by experience."  
    direction="flex-row" 
    image={MobileMoneyImage} 
  />
  <Dual  
    title="See and control your kids' and teens' spending" 
    width={488} 
    height={452} 
    desc="Our mobile app allows you to monitor how, when, and where your kids spend their money. You will receive real-time updates every time they make a transaction—giving you total peace of mind!" 
    direction="flex-row-reverse" 
    image={ShoppiongImage} 
  />
</div>

     
       </section>   

      <ReactSlider/>
      <Features/>  
      <RounderImage/>  
      
      <Reviews/>  
      
      <ImageSection/>  
    
      </div > 
    
  )
}

export default HomePage
